// Woocommerce scripts
/* global ajax_object */
$(() => {
  /*const $body = $('body');
const isWoocommercePage = $body.hasClass('woocommerce-page');
const isCheckoutPage = $body.hasClass('woocommerce-checkout');
const isCartPage = $body.hasClass('woocommerce-cart');*/
  const $woocommerce = $('.woocommerce');

  // -/+ buttons for a product amount input.
  $woocommerce.on(
    'click',
    '.js-add-to-cart-plus, .js-add-to-cart-minus',
    (event) => {
      let $this = $(event.currentTarget);

      // Get current quantity values.
      let $qty;

      if (!$('body').hasClass('woocommerce-cart')) {
        // for .cart (the single product page).
        $qty = $this.closest('form.cart').find('input[type="number"].qty');
      } else {
        // for .woocommerce-cart-form (the cart page).
        $qty = $this
          .closest('.js-cart-quantity')
          .find('input[type="number"].qty');
      }

      let val = parseFloat($qty.val());
      let max = $qty.attr('max') ? parseFloat($qty.attr('max')) : 9999;
      let min = $qty.attr('min') ? parseFloat($qty.attr('min')) : 0;
      let step = $qty.attr('step') ? parseFloat($qty.attr('step')) : 1;

      // Change the value if plus or minus.
      if ($this.is('.js-add-to-cart-plus')) {
        if (max && max <= val) {
          $qty.val(max);
        } else {
          $qty.val(val + step);
        }
      } else {
        if (min && min >= val) {
          $qty.val(min);
        } else if (val > 0.5) {
          $qty.val(val - step);
        }
      }

      $qty.trigger('input');

      //$('button[name="update_cart"]').trigger('click');
    }
  );

  // Main document ready
  $(document).ready(function () {
    // Free gift product functionality
    $('#cart-free-product').on('change', function () {
      let checkbox = $(this),
        id = checkbox.data('id');

      let data = {
        action: 'wc_add_to_cart',
        product_id: id,
      };
      console.log(data);
      $.ajax({
        // you can also use $.post here
        url: ajax_object.ajax_url, // AJAX handler
        data: data,
        type: 'POST',
        beforeSend: function () {
          // button.text('Loading...'); // change the button text, you can also add a preloader image
        },
        success: function () {
          $(document.body).trigger('wc_fragment_refresh');
          $(document.body).trigger('added_to_cart');
          setTimeout(function () {
            checkbox.prop('disabled', false);
          }, 200);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          // For debugging, console log any errors. In production, do something different.
          console.log(xhr.status);
          console.log(thrownError);
        },
      });
    });

    // Trigger checkout if address is changed
    triggerUpdateCheckout();
    triggerUpdateCheckout('shipping');

    // Update address fields if hidden
    $(document.body).on('update_checkout', function () {
      prefillAddress();
      prefillAddress('shipping');
    });

    // Check if address has number
    $(document.body).on('updated_checkout', function () {
      prefillAddress();
      prefillAddress('shipping');
      validateAddressField();
      validateAddressField('shipping_address_1');
    });

    $(document.body).on('validate', 'form.checkout', function () {
      validateAddressField();
      validateAddressField('shipping_address_1');
    });

    function validateAddressField($fileId = 'billing_address_1') {
      let $billingAddress = $(document).find('#' + $fileId);
      const regex = /\d+/;

      if ($billingAddress.length && $billingAddress.val()) {
        if (!$billingAddress.val().match(regex)) {
          $billingAddress
            .closest('.validate-required')
            .removeClass('woocommerce-validated')
            .addClass('woocommerce-invalid');
        }
      }
    }

    function prefillAddress($type = 'billing') {
      let $wrapper = $(document).find('#' + $type + '_address_1_field:hidden');
      let $addressInput = $(document).find('#' + $type + '_address_1');

      if ($wrapper.length && $addressInput.length) {
        let address = '';
        let streetName = $(document).find('#' + $type + '_street_name');
        let streetNum = $(document).find('#' + $type + '_house_number');
        let streetNumSuffix = $(document).find(
          '#' + $type + '_house_number_suffix'
        );

        if (streetName.length) {
          address += streetName.val();
        }
        if (streetNum.length) {
          address += ' ' + streetNum.val();
        }
        if (streetNumSuffix.length) {
          address += '-' + streetNumSuffix.val();
        }

        // Put full address to the field
        $addressInput.val(address).trigger('change');
      }
    }

    function triggerUpdateCheckout($type = 'billing') {
      $(document).on(
        'change',
        '#' +
          $type +
          '_street_name, #' +
          $type +
          '_house_number, #' +
          $type +
          '_house_number_suffix, #' +
          $type +
          '_postcode',
        function () {
          if (
            $(document).find('#' + $type + '_address_1_field:hidden').length
          ) {
            $(document.body).trigger('update_checkout');
          }
        }
      );
    }
  });

  // Remove responsive styles
  $('body.woocommerce-cart')
    .find('.cart_totals .shop_table')
    .removeClass('shop_table_responsive');

  // Set min value to the quantity as the default one.
  $(() => {
    let $qty = !$('body').hasClass('woocommerce-cart')
      ? $('form.cart').find('input[type="number"].qty')
      : null;
    if ($qty) {
      let min = $qty && $qty.attr('min') ? parseFloat($qty.attr('min')) : 1;
      $qty.val(min);
    }
  });
});
